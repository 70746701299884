import React, { useState } from "react"

import Modal from "./modal"

type VideoProps = { children: (fn: () => void) => JSX.Element }

export default function Video(props: VideoProps) {
  const [modalIsVisible, setModalVisibility] = useState(false)
  const openModal = () => setModalVisibility(true)
  const closeModal = () => setModalVisibility(false)

  return (
    <>
      {props.children(openModal)}

      {modalIsVisible && <Modal onClose={closeModal} />}
    </>
  )
}
