import React, { PropsWithChildren } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useSiteMetadata } from "hooks"

type SeoProps = PropsWithChildren<{
  pagePath: string
  title?: string
  description?: string
}>

export default function Seo({
  pagePath,
  title: pageTitle,
  description: pageDescription,
  children,
}: SeoProps) {
  const {
    siteUrl,
    title: siteTitle,
    description: siteDescription,
  } = useSiteMetadata()

  const logoQuery = useStaticQuery<Queries.SocialMediaLogosQuery>(graphql`
    query SocialMediaLogos {
      openGraphLogo: file(name: { eq: "open-graph-logo" }) {
        publicURL
      }
    }
  `)

  if (
    logoQuery.openGraphLogo === null ||
    logoQuery.openGraphLogo.publicURL === null
  )
    throw new Error("Logo for Open Graph metadata is missing")

  const openGraphLogoUrl = siteUrl + logoQuery.openGraphLogo.publicURL

  const title = pageTitle || siteTitle
  const description = pageDescription || siteDescription
  const pageUrl = siteUrl + pagePath

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={openGraphLogoUrl} />

      {children}
    </>
  )
}
