import React from "react"

import pieoneersLogo from "images/svg/pieoneers_logo.svg"

export default function Footer() {
  const currentYear: number = new Date().getFullYear()

  return (
    <footer>
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center space-y-3 border-t border-secondary-light py-6 text-sm text-secondary-darken">
          <p>&copy; {currentYear} Central Sampling</p>
          <p className="flex flex-row items-center">
            Web Developer
            <a
              href="https://pieoneers.com/"
              className="flex items-center text-primary"
            >
              <img
                className="mx-2 inline-block"
                src={pieoneersLogo}
                alt=""
                loading="eager"
              />
              Pieoneers Software Inc.
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}
