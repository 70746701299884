import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { SubmitHandler, useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { yupResolver } from "@hookform/resolvers/yup"
import { InferType, object, string } from "yup"
import { toast } from "react-toastify"
import classNames from "classnames"
import { BiChevronDown } from "react-icons/bi"

const PROVINCES = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Nova Scotia",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Northwest Territories",
  "Nunavut",
  "Yukon",
]
const PRACTICE_TYPES = [
  "General Practitioner/Family Medicine",
  "Nurse Practitioner",
  "Specialist",
]

const schema = object({
  firstName: string().required("Please enter your first name"),
  lastName: string().required("Please enter your last name"),
  email: string()
    .email("Please enter valid email address")
    .required("Please enter your email address"),
  province: string().required("Please select your province"),
  practiceType: string().required("Please select your practice type"),
})
type Form = InferType<typeof schema>

type EarlyAccessFormProps = { onSuccess: () => void }

export default function EarlyAccessForm(props: EarlyAccessFormProps) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitted, isValid, errors },
  } = useForm<Form>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      province: "",
      practiceType: "",
    },
    resolver: yupResolver(schema),
  })

  const submit: SubmitHandler<Form> = async data => {
    const { firstName, lastName, email, province, practiceType } = data

    const fields = {
      FNAME: firstName,
      LNAME: lastName,
      MMERGE6: province,
      MMERGE7: practiceType,
    }

    const feedback = await addToMailchimp(email, fields)

    console.debug(feedback)

    const validFeedback = object({
      result: string().required().oneOf(["success", "error"]),
      msg: string().required(),
    }).validateSync(feedback)

    console.debug(validFeedback)

    if (validFeedback.result === "success") toast.success(validFeedback.msg)
    if (validFeedback.result === "error") toast.error(validFeedback.msg)

    props.onSuccess()
  }

  return (
    <form className="mb-4" onSubmit={handleSubmit(submit)}>
      <div className="mb-6 space-y-3">
        <div>
          <label
            className="mb-1.5 block text-sm text-default"
            htmlFor="firstName"
          >
            First Name
          </label>

          <input
            className={classNames(
              "w-full appearance-none rounded-md border border-secondary-light px-4 py-3 text-sm text-default outline-none transition placeholder:text-secondary-light",
              "focus-visible:border-primary focus-visible:shadow-[0_0_4px] focus-visible:shadow-primary/25",
              {
                "border-danger focus-visible:border-danger focus-visible:shadow-danger/25":
                  errors.firstName,
              },
            )}
            {...register("firstName")}
            placeholder="e.g. Jane"
            id="firstName"
          />

          <ErrorMessage
            errors={errors}
            name="firstName"
            as={<p className="mt-1 text-sm text-red-500" />}
          />
        </div>

        <div>
          <label
            className="mb-1.5 block text-sm text-default"
            htmlFor="lastName"
          >
            Last Name
          </label>

          <input
            className={classNames(
              "w-full appearance-none rounded-md border border-secondary-light px-4 py-3 text-sm text-default outline-none transition placeholder:text-secondary-light",
              "focus-visible:border-primary focus-visible:shadow-[0_0_4px] focus-visible:shadow-primary/25",
              {
                "border-danger focus-visible:border-danger focus-visible:shadow-danger/25":
                  errors.lastName,
              },
            )}
            {...register("lastName")}
            placeholder="e.g. Smith"
            id="lastName"
          />

          <ErrorMessage
            errors={errors}
            name="lastName"
            as={<p className="mt-1 text-sm text-red-500" />}
          />
        </div>

        <div className="mc-field-group">
          <label className="mb-1.5 block text-sm text-default" htmlFor="email">
            Email Address
          </label>

          <input
            className={classNames(
              "w-full appearance-none rounded-md border border-secondary-light px-4 py-3 text-sm text-default outline-none transition placeholder:text-secondary-light",
              "focus-visible:border-primary focus-visible:shadow-[0_0_4px] focus-visible:shadow-primary/25",
              {
                "border-danger focus-visible:border-danger focus-visible:shadow-danger/25":
                  errors.email,
              },
            )}
            {...register("email")}
            placeholder="e.g. jane.smith@gmail.com"
            id="email"
          />

          <ErrorMessage
            errors={errors}
            name="email"
            as={<p className="mt-1 text-sm text-red-500" />}
          />
        </div>

        <div className="mc-field-group">
          <label
            className="mb-1.5 block text-sm text-default"
            htmlFor="province"
          >
            Province
          </label>

          <div className="relative">
            <select
              className={classNames(
                "relative z-10 w-full appearance-none rounded-md border border-secondary-light bg-transparent px-4 py-3 text-sm text-default outline-none transition placeholder:text-secondary-light",
                "focus-visible:border-primary focus-visible:shadow-[0_0_4px] focus-visible:shadow-primary/25",
                {
                  "border-danger focus-visible:border-danger focus-visible:shadow-danger/25":
                    errors.province,
                },
              )}
              {...register("province")}
              id="province"
            >
              <option value="" disabled>
                Select province…
              </option>
              {PROVINCES.map(province => (
                <option value={province} key={province}>
                  {province}
                </option>
              ))}
            </select>
            <BiChevronDown className="absolute right-4 top-1/2 z-0 -translate-y-1/2 transform" />
          </div>

          <ErrorMessage
            errors={errors}
            name="province"
            as={<p className="mt-1 text-sm text-red-500" />}
          />
        </div>

        <div className="mc-field-group">
          <label
            className="mb-1.5 block text-sm text-default"
            htmlFor="practice-type"
          >
            Practice Type
          </label>

          <div className="relative">
            <select
              className={classNames(
                "relative z-10 w-full appearance-none rounded-md border border-secondary-light bg-transparent px-4 py-3 text-sm text-default outline-none transition placeholder:text-secondary-light",
                "focus-visible:border-primary focus-visible:shadow-[0_0_4px] focus-visible:shadow-primary/25",
                {
                  "border-danger focus-visible:border-danger focus-visible:shadow-danger/25":
                    errors.practiceType,
                },
              )}
              {...register("practiceType")}
              id="practice-type"
            >
              <option value="" disabled>
                Select practice…
              </option>
              {PRACTICE_TYPES.map(practiceType => (
                <option value={practiceType} key={practiceType}>
                  {practiceType}
                </option>
              ))}
            </select>
            <BiChevronDown className="absolute right-4 top-1/2 z-0 -translate-y-1/2 transform" />
          </div>

          <ErrorMessage
            errors={errors}
            name="practiceType"
            as={<p className="mt-1 text-sm text-red-500" />}
          />
        </div>
      </div>

      <button
        className="block w-full rounded-md bg-primary px-5 py-2.5 text-sm text-white shadow-button disabled:opacity-50"
        disabled={!isValid && isSubmitted}
        type="submit"
      >
        Get Early Access
      </button>
    </form>
  )
}
