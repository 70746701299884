import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { AiOutlineMail } from "react-icons/ai"

import { EarlyAccess, Video } from "components"

import logoHorizontal from "images/svg/CentralSampling_logo_horizontal.svg"
import logo from "images/svg/CentralSampling_logo.svg"

export default function Header() {
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024 && window.innerWidth >= 768)
    }
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize)
      setIsSmallScreen(window.innerWidth < 1024 && window.innerWidth >= 768)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [])

  return (
    <>
      <header>
        <div className="container mx-auto p-4">
          <div className="flex flex-col items-center justify-between py-0.5 md:flex-row">
            <Link to="/" className="mb-6 md:mb-0">
              <img
                src={isSmallScreen ? logo : logoHorizontal}
                alt="Central Sampling logo"
                loading="eager"
                className="h-[2.125rem]"
              />
            </Link>

            <nav className="flex flex-row items-center justify-between space-x-2 md:justify-normal md:space-x-4">
              <Link
                to="/about"
                className="rounded-md px-2 py-2.5 text-xs leading-4 md:px-3.5 md:text-base"
              >
                About Us
              </Link>

              <EarlyAccess>
                {onClick => (
                  <button
                    className="rounded-md px-2 py-2.5 text-xs leading-4 md:px-3.5 md:text-base"
                    onClick={onClick}
                    type="button"
                  >
                    Get Early Access
                  </button>
                )}
              </EarlyAccess>

              <Video>
                {onClick => (
                  <button
                    className="rounded-md px-2 py-2.5 text-xs leading-4 md:px-3.5 md:text-base"
                    onClick={onClick}
                    type="button"
                  >
                    Sneak Preview
                  </button>
                )}
              </Video>

              <Link
                to="/contact"
                className="rounded-md bg-primary px-3.5 py-2.5 text-xs leading-4 text-white shadow-button md:text-base"
              >
                <span className="hidden md:inline">Contact Us</span>
                <AiOutlineMail className="inline-block md:hidden" />
              </Link>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}
