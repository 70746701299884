import React from "react"

import pieoneersLogo from "images/svg/pieoneers_logo.svg"
import logoHorizontal from "images/svg/CentralSampling_logo_horizontal.svg"

export default function ComingSoon() {
  return (
    <div className="flex min-h-screen flex-col bg-white">
      <header>
        <div className="container mx-auto p-4 md:py-7">
          <div className="flex justify-center py-0.5">
            <img
              src={logoHorizontal}
              alt="Central Sampling logo"
              loading="eager"
              className="h-[2.125rem]"
            />
          </div>
        </div>
      </header>
      <main className="relative flex grow flex-col">
        <div className="container mx-auto flex  grow justify-center">
          <div className="bg-cs relative grid min-h-full w-[90%] place-content-center rounded-xl bg-cover bg-top">
            <p className="text-[3rem] font-bold text-white md:text-[5rem] lg:text-[7rem]">
              Coming Soon
            </p>
          </div>
        </div>
      </main>
      <footer>
        <div className="container mx-auto px-4">
          <div className="flex flex-col items-center py-6 text-sm text-secondary-darken md:py-10">
            <p className="flex flex-row items-center">
              Web Developer
              <a
                href="https://pieoneers.com/"
                className="flex items-center text-primary"
              >
                <img
                  className="mx-2 inline-block"
                  src={pieoneersLogo}
                  alt=""
                  loading="eager"
                />
                Pieoneers Software Inc.
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}
