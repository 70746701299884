import React, { useEffect } from "react"
import { createPortal } from "react-dom"
import { Link } from "gatsby"

import logo from "images/svg/CentralSampling_logo.svg"
import EarlyAccessForm from "./form"

type ModalProps = { onClose: () => void }

export default function Modal({ onClose: close }: ModalProps) {
  useEffect(() => {
    const initialBodyOverflow = document.body.style.overflow

    document.body.style.overflow = "hidden"

    return function cleanup() {
      document.body.style.overflow = initialBodyOverflow
    }
  }, [])

  return createPortal(
    <div className="fixed left-0 top-0 z-50 flex h-screen w-full items-center justify-center sm:bg-black/60">
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-white outline-none focus:outline-none sm:bg-inherit">
        <div className="relative mx-auto h-full min-h-full bg-white sm:h-auto sm:min-h-0 sm:max-w-xl sm:bg-inherit">
          <div className="bg-white px-6 py-8 outline-none focus:outline-none sm:rounded-2xl sm:px-24">
            <div className="flex flex-col items-center text-center">
              <img
                className="mb-2.5 inline-block w-16"
                src={logo}
                alt=""
                loading="eager"
              />
              <h4 className="mb-1 text-[1.75rem] font-bold leading-[1.2]">
                Welcome to Central Sampling
              </h4>
              <p className="mb-6 text-gray-400">
                Secure your spot for exclusive early access
                <br />
                to Central Sampling by registering now and be
                <br />
                among the first to explore its innovative features!
              </p>
              <button
                className="absolute left-[calc(100%_-_3rem)] top-6 scale-100 hover:scale-110"
                onClick={close}
                type="button"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.2092 1.3375C23.4189 1.54933 23.5368 1.83664 23.5368 2.13621C23.5368 2.43578 23.4189 2.72309 23.2092 2.93492L2.64914 23.7013C2.43942 23.9131 2.15497 24.0321 1.85837 24.0321C1.56177 24.0321 1.27732 23.9131 1.0676 23.7013C0.857871 23.4895 0.740048 23.2022 0.740048 22.9026C0.740048 22.603 0.857871 22.3157 1.0676 22.1039L21.6277 1.3375C21.8374 1.12567 22.1218 1.00667 22.4184 1.00667C22.715 1.00667 22.9995 1.12567 23.2092 1.3375Z"
                    fill="#161616"
                  />
                  <path
                    d="M22.9324 23.6625C22.7226 23.8743 22.4382 23.9933 22.1416 23.9933C21.845 23.9933 21.5605 23.8743 21.3508 23.6625L0.79074 2.89612C0.581014 2.68429 0.463191 2.39699 0.463191 2.09741C0.463191 1.79784 0.581015 1.51054 0.79074 1.29871C1.00047 1.08688 1.28492 0.967871 1.58151 0.967871C1.87811 0.967871 2.16256 1.08688 2.37228 1.29871L22.9324 22.0651C23.1421 22.2769 23.2599 22.5642 23.2599 22.8638C23.2599 23.1634 23.1421 23.4507 22.9324 23.6625Z"
                    fill="#161616"
                  />
                </svg>
              </button>
            </div>
            <div className="relative flex-auto">
              <EarlyAccessForm onSuccess={close} />

              <p className="text-center text-xs text-gray-400">
                By continuing, you acknowledge that you have read our{" "}
                <Link to="/privacy-policy" className="text-primary">
                  Privacy Policy
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  )
}
