import React, { PropsWithChildren } from "react"
import { ToastContainer } from "react-toastify"
import classNames from "classnames"

import Header from "./header"
import Footer from "./footer"
import ComingSoon from "./coming-soon"

const isUnderConstruction = true

export default function Layout({ children }: PropsWithChildren) {
  return (
    <>
      {isUnderConstruction ? (
        <ComingSoon />
      ) : (
        <div className="flex min-h-screen flex-col">
          <Header />
          <main className="relative flex grow flex-col">{children}</main>
          <Footer />
        </div>
      )}

      <ToastContainer
        className="max-w-prose"
        toastClassName="!rounded-lg"
        bodyClassName="text-base p-3"
        progressClassName={data =>
          classNames(data?.defaultClassName, {
            "bg-primary": data?.type === "default",
          })
        }
        position="bottom-right"
        theme="colored"
        autoClose={5000}
        closeButton={false}
      />
    </>
  )
}
