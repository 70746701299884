import { useStaticQuery, graphql } from "gatsby"

export type SiteMetadata = {
  siteUrl: string
  title: string
  description: string
  author: string
}

export default function useSiteMetadata(): SiteMetadata {
  const { site } = useStaticQuery<Queries.SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          description
          author
        }
      }
    }
  `)

  if (site === null || site.siteMetadata === null)
    throw Error("Failed to load site metadata")
  if (site.siteMetadata.siteUrl === null)
    throw Error("Site URL is missing in site metadata")
  if (site.siteMetadata.title === null)
    throw Error("Title is missing in site metadata")
  if (site.siteMetadata.description === null)
    throw Error("Description is missing in site metadata")
  if (site.siteMetadata.author === null)
    throw Error("Author is missing in site metadata")

  const { siteUrl, title, description, author } = site.siteMetadata

  return {
    siteUrl,
    title,
    description,
    author,
  }
}
